.product-title{
    width: 100%;
    height: 40vh;
    background: $gray-novo4;
    padding-top: 6rem;
    .container{
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        h1{
            color: $gray-novo3;
            font-weight: 300;
        }
    }
}

.product-content{
    padding: 6rem;
    color: $gray-novo3;
    display: flex;
    align-items: center;
    .product-arrow-left {
        width: 5%;
        position: absolute;
        left: -3vw;
        top: 9vw;
        transition: transform .2s;
        transition-timing-function: ease-in;

        &:hover {
            transform: scale(1.5);
        }

        img:hover {
            filter: opacity(0.5);
        }
    }
    .description{
        text-align: center;
        h4, p, ul{
            text-align: left;
        }
        p, ul{
            font-size: 20px;
            font-weight: 300;
            line-height: 28px;
        }
        h4{
            font-size: 40px;
            color: $green-novo;
            text-transform: uppercase;
            margin-bottom: 2rem;
        }
        p{ margin-bottom: 2rem; }
        ul{
            margin-bottom: 3rem;
            li{
                list-style: none;
                margin-bottom: .3rem;
                span{ font-weight: 500; }
                &::before{
                    content: "\2022";
                    color: $green-novo;
                    font-weight: bold;
                    display: inline-block; 
                    width: 1em;
                    margin-left: -1em;
                }
            }
        }
    }
    .product{
        text-align: center;
        .product-img{ width: 50%; }
        hr{ margin-bottom: 1.4rem; }
        .features{
            display: flex;
            align-items: center;
            justify-content: center;
            margin-bottom: 1rem;
            .feature-item{
                color: $gray-novo5;
                align-self: center;
                padding:.6rem 2rem;
                img{
                    width: 30px;
                    margin-right: 1rem;
                }
                &:nth-child(2){
                    border-left: 1px solid $gray-novo5;
                    border-right: 1px solid $gray-novo5;
                }
            }
        }
        p{ text-align: left;}
        .colors{
            text-align: left;
            margin-bottom: 1rem;
            .color{
                display: inline-block;
                margin-right: .6rem;
                border-radius: 50%;
                width: 30px;
                height: 30px;
            }
        }
        .note{
            color: $gray-novo5;
            font-size: 16px;
        }
    }
}

.tooltip-inner{
    background-color:#228496;
}
.tooltip.bs-tooltip-auto[x-placement^=top] .arrow::before, .tooltip.bs-tooltip-top .arrow::before {
    border-top-color:#228496;
}

.proyects{
    background: $gray-novo4;
    padding: 6rem;
    h2{
        text-align: center;
        font-size: 40px;
        color: $green-novo;
        font-weight: 300;
        margin-bottom: 2rem;
    }
    .owl-proyects{
        position: relative;
        .item{
            text-align: center;
            color: $gray-novo3;
            img{margin-bottom: 1.5rem;}
            h6{
                font-size: 20px;
                text-transform: uppercase;
                margin-bottom: 1rem;
            }
            p{ font-weight: 300; }
        }
        .owl-dots{
            position: relative;
            width: 100%;
            height: 20px;
            top: 2rem;
            display: flex;
            justify-content: center;
            .owl-dot{
                margin: 0 .5rem;
                width: 15px;
                height: 15px;
                border-radius: 50%;
                background: #333333aa;
                transition: all 0.2s ease;
                &.owl-dot:hover,
                &.owl-dot.active {
                    background: #333333;
                }
                &:focus{ outline: none; }
            }
        } 
        
        .owl-nav {
            width: 100%;
            margin: 0;
            position: absolute;
            top: 30%;
            .owl-prev,
            .owl-next{
                position: absolute;
                width:50px;
                height:50px;
                background: #ffffffaa;
                img{
                    width: 35px;
                    height: 35px;
                }
                &:focus{ outline: none; }
            }
            .owl-prev{ left: 0rem; }
            .owl-next{ right: 0rem; }
        }
    }
}