.video-container{
    --video--width: 1920;
    --video--height: 1080;
    position:relative;
    padding-bottom:calc(var(--video--height) / var(--video--width) * 100%);
    overflow:hidden;
    max-width:100%;
    iframe, object, embed {
        position:absolute;
        top:0;
        left:0;
        width:100%;
        height:100%;
    }
}
.about-us{
    width: 100%;
    height: 100vh;
    background-image: url('../img/about_us/about_header.jpg');
    background: cover;
    padding: 4rem;
    padding-bottom: 0;
    .container-fluid{
        width: 100%;
        height: 100%;
        .row{
            height: 100%;
            align-items: center;
            .title{
                display: flex;
                flex-direction: column;
                text-transform: uppercase;
                text-align: center;
                text-shadow: 2px 2px 4px #00000066;
                .nosotros{
                    font-size: 3.8rem;
                    line-height: .2;
                    color: #fff;
                    font-weight: 700;
                }
                .somos{
                    font-size: 6rem;
                    line-height: 1.4;
                    color: #fff;
                }
                .novo{
                    font-size: 7.4rem;
                    line-height: .4;
                    color: $green-novo;
                    font-weight: 500;
                }
                .eco{
                    font-size: 2rem;
                    line-height: 2.8;
                    color: $green-novo;
                    font-weight: 700;
                }
            }

            .video{
                display: flex;
                justify-content: center;
                iframe{
                    width: 90%;
                    min-height: 600px;
                }
            }
        }
    }
}

.about-description{
    .row-one,.row-two{
        background-size: cover;
        .col-green{
            background: $green-novo;
            padding: 8rem;
            text-align: center;
            position: relative;
            p{
                font-size: 18px;
                text-align: left;
                color: #fff;
                font-weight: 300;
                line-height: 22px;
            }
        }
    }
    .row-one{
        position: relative;
        &::before {
            background-image: url('../img/about_us/Fabrica-tenjo.jpg');
            @include bg-grayscale;
        }
        .col-green{
            .logo-novo{
                width: 280px;
                margin-bottom: 4rem;
            }
            p{
                a{
                    color: #fff;
                    font-weight: 500;
                    text-decoration: underline #fff;
                }
            }
            svg{
                position: absolute;
                right: -24%;
                top: 0;
                height: 100%;
                width: 10vw;
            }
        }
    }

    .row-two{
        position: relative;
        &::before {
            background-image: url('../img/about_us/nosotros.jpg');
            @include bg-grayscale;
        }        
        .row{
            margin-bottom: 1.5rem;
            margin-top: 1.5rem;
        }
        .col-green{
            img{
                max-width: 50px;
                max-height: 60px;
            }
            p{
                margin: 0;
                span{
                    color: #fff;
                    font-weight: 500;
                }
            }
            svg{
                position: absolute;
                left: -24%;
                top: 0;
                height: 100%;
                width: 10vw;
            }
        }
    }
}

.allies{
    background: $gray-novo1;
    padding: 6rem;
    p{
        color: $green-novo;
        padding-left: 2rem;
        font-size: 32px;
        line-height: 36px;
        text-transform: uppercase;
        font-weight: 500;
    }
}